import React, { useEffect } from 'react';
import { Link } from "gatsby"
import {
  CalendarIcon,
} from '@heroicons/react/outline'
import { Helmet } from "react-helmet"

import Menu2 from "../components/menu2"
import Footer from "../components/footer"
import banner from "../images/interviews-page-banner.png"

import { useSelector } from 'react-redux';
import { fetchInterviews, fetchPastInterviews } from '../store/actions'

const perPage = 10;

const twIcon = () => {
  return (
    <svg fill="currentColor" viewBox="0 0 24 24" className="inline-block w-5 h-5 mr-1 face-twitter">
      <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
    </svg>
  )
}

const Pagination = (metaData, requestData) => {
  const meta = metaData.meta
  if (meta == undefined) return '';

  const totalRec = parseInt(meta.count);
  let showingTo = parseInt(meta.page) * perPage;
  if (showingTo + perPage > totalRec) showingTo = totalRec;
  const showingFrom = showingTo - perPage + 1;
  const next = parseInt(meta.page) + 1;
  const previous = parseInt(meta.page) - 1;

  return (
    <nav
      className="bg-white mt-24 px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
      aria-label="Pagination"
    >
      <div className="hidden sm:block">
        <p className="text-sm text-gray-700">
          Showing <span className="font-medium">{showingFrom}</span> to <span className="font-medium">{showingTo}</span> of{' '}
          <span className="font-medium">{meta.count}</span> results
        </p>
      </div>
      <div className="flex-1 flex justify-between sm:justify-end">
        <a
          href="#"
          onClick={()=>{fetchPastInterviews(previous,10)}}
          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            style={previous == 0 ? {pointerEvents: 'none', cursor: 'default', opacity: '0.6'} : {} }
        >
          Previous
        </a>
        <a
          href="#"
          onClick={()=>{fetchPastInterviews(next,10)}}
          className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            style={parseInt(meta.page) * perPage > totalRec ? {pointerEvents: 'none', cursor: 'default', opacity: '0.6'} : {} }
        >
          Next
        </a>
      </div>
    </nav>
  )
}

const Avatar = (item) => {
    if (item.twitter_account && item.avatar_url) {
        return item.avatar_url
    } else {
        return `https://ui-avatars.com/api/?size=256&background=0D8ABC&color=fff&name=${item.guest.replace('@', '').split(' ').join('+')}`;
    }
}

const Card = ({item}) => {
  const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
  const d = new Date(item.interview_date);
  let year = "";
  if (d.getFullYear() < new Date().getFullYear()) year = d.getFullYear();

  const date = d.getDate() + ' ' + monthNames[d.getMonth()] + ' ' + year; // + ' ' + d.getFullYear();
  console.log(item);
  const videoUrl = `https://www.youtube.com/embed/${item.link_to_webinar}`;
  const modalId = `ID${item.link_to_webinar}`
  return (
    <div className="flex mt-14">
      <div className="mr-4 flex-shrink-0 self-center">
        <img
          src={Avatar(item)}
          className="rounded-full w-32"
          alt="Avatar"
          // onError={(e)=>{e.onerror=null;e.src='http://www.gravatar.com/avatar/?d=mp';}}
        />
      </div>
      <div>

        <div className="flex items-start space-x-3 text-2xl">
          <dt className="mt-0.5">
            <span className="sr-only">Date</span>
              <CalendarIcon className="h-7 w-7 text-gray-600 mb-4" aria-hidden="true"/>
          </dt>
          <dd>
            <time className="text-gray-600">
              {date}
            </time>
          </dd>
        </div>

        <h2 className="text-2xl font-bold">{item.guest}</h2>
        <div className="text-xl face-twitter hover:underline">
          {item.twitter_account ? (
            <a href={`https://twitter.com/${item.twitter_account.replace('@', '')}`} target="_blank">
              {twIcon()}{item.twitter_account}
            </a>
          ) :( "" )}

        </div>
        <p className="mt-1 text-xl">
          {item.description}
        </p>
        {item.link_to_webinar ? (
            <div>
              <div className="mt-4">
                <button
                  type="button"
                  data-bs-toggle="modal" data-bs-target={`#${modalId}`}
                  className="inline-flex px-4 py-2 border border-transparent text-base font-medium shadow-sm text-white face-blue-bg face-blue-btn-hover"
                >
                  Watch Video
                </button>
              </div>

              <div class="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
                id={modalId} tabindex="-1" aria-labelledby="exampleModalXlLabel" aria-modal="true" role="dialog"
                data-bs-backdrop="static"
              >
                <div class="modal-dialog modal-xl relative w-auto pointer-events-none">
                  <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding outline-none text-current">
                    <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                      <h5 class="text-xl font-medium leading-normal text-gray-800" id="exampleModalXlLabel">
                        {item.twitter_account} interview, published {date}
                      </h5>
                      <button type="button"
                        class="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                        data-bs-dismiss="modal" aria-label="Close"
                        onClick={()=>{
                          if (typeof window !== "undefined");
                            window.location.reload(); // if we dont reload the page, the video will continue to play
                        }}>
                      </button>
                    </div>
                    <div class="modal-body relative p-4">
                        <div className="aspect-w-16 aspect-h-9">
                        <iframe src={videoUrl} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
        ) : ""}
      </div>
    </div>
  )
}

export default function Interviews() {

  useEffect(() => {
    fetchInterviews(1,200);
    fetchPastInterviews(1,perPage);
    import('tw-elements');
  },[]);

  const interviews = useSelector(state => state.interviews);
  const pastInterviews = useSelector(state => state.pastInterviews);

  return (
    <>
      <Helmet title="FACE - Interviews"/>
      <Menu2 />

      <section class="face-dark-blue-bg">
          <img class="max-w-full h-auto mx-auto max-h-72" src={banner} alt="FACE - Interviews" />
      </section>

      <div className="mb-24 bg-white min-h-full px-4 py-4 sm:px-6 sm:py-4 md:grid md:place-items-center lg:px-8">
        <div className="max-w-max mx-auto">
          <main className="sm:flex">
            <div className="sm:ml-6">

              <ul className="
                nav nav-tabs nav-justified
                flex flex-col
                md:flex-row
                flex-wrap
                list-none
                border-b-0
                pl-0
                mb-4
              " id="tabs-tabJustify" role="tablist">
                <li className="nav-item flex-grow text-center" role="presentation">
                  <a href="#tabs-homeJustify" className="
                    nav-link
                    w-full
                    block
                    font-medium
                    text-xl
                    leading-tight
                    uppercase
                    border-x-0 border-t-0 border-b-2 border-transparent
                    px-6
                    py-3
                    my-2
                    hover:border-transparent hover:bg-gray-100
                    focus:border-transparent
                    active
                  " id="tabs-home-tabJustify" data-bs-toggle="pill" data-bs-target="#tabs-homeJustify" role="tab"
                    aria-controls="tabs-homeJustify" aria-selected="true">
                      Scheduled Interviews
                  </a>
                </li>
                <li className="nav-item flex-grow text-center" role="presentation">
                  <a href="#tabs-profileJustify" className="
                    nav-link
                    w-full
                    block
                    font-medium
                    text-xl
                    leading-tight
                    uppercase
                    border-x-0 border-t-0 border-b-2 border-transparent
                    px-6
                    py-3
                    my-2
                    hover:border-transparent hover:bg-gray-100
                    focus:border-transparent
                  " id="tabs-profile-tabJustify" data-bs-toggle="pill" data-bs-target="#tabs-profileJustify" role="tab"
                    aria-controls="tabs-profileJustify" aria-selected="false">
                      Past Interviews
                    </a>
                </li>
              </ul>
              <div className="tab-content" id="tabs-tabContentJustify">
                <div className="max-w-4xl tab-pane fade show active" id="tabs-homeJustify" role="tabpanel"
                  aria-labelledby="tabs-home-tabJustify">
                  <p className="text-xl">
                    The F.A.C.E. interviews are conducted at 8am ET (1pm UK time) and typically last around 30 minutes
                  </p>
                  {interviews.items && interviews.items.map((item, i)=>{
                    return(
                      <Card item={item} key={i} />
                    )
                  })}
                </div>
                <div className="max-w-4xl tab-pane fade" id="tabs-profileJustify" role="tabpanel" aria-labelledby="tabs-profile-tabJustify">
                  {pastInterviews.items && pastInterviews.items.map((item, i)=>{
                    return(
                      <Card item={item} key={i} />
                    )
                  })}

                  <Pagination
                    meta={pastInterviews.meta}
                    requestData={fetchPastInterviews}
                  />
                </div>
              </div>


            </div>
          </main>
        </div>
      </div>

      <Footer />
    </>
  )
}
