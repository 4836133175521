import axios from 'axios';
import store from './reduxStore';

const YT_API_KEY = 'AIzaSyAKXwX5Yrq9CU13y9YZKbDc1z9N_8fZONs';
const YT_CHANNEL_ID = 'UCnAQSmtLxwt6ZMQ6V6pZw8w'
const SERVICE_URL = 'https://app.forexanalytix.com/api/v1'

const setVideoData = payload => ({
    type: 'SET_YT_VIDEO',
    payload
});

const setSearchResults = payload => ({
    type: 'SET_YT_SEARCH_RESULTS',
    payload
});

const setAllVideos = payload => ({
    type: 'SET_YT_ALL_VIDEOS',
    payload
});

const setInterviewsData = payload => ({
    type: 'SET_YT_INTERVIEWS',
    payload
});

const setPastInterviewsData = payload => ({
    type: 'SET_YT_PAST_INTERVIEWS',
    payload
});


export const fetchVideo = (videoId) => {
  let url = `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&part=snippet&key=${YT_API_KEY}`
    axios.get(url).then(response => {
        store.dispatch(setVideoData({ data: response.data.items[0]}));
    })
    .catch(error => {
        if (error.response) console.log('Error:', error.response);
    });
}

export const fetchInterviews = (page=1, perPage=20) =>  {
  let url = SERVICE_URL + `/webinars/upcoming_interviews?page=${page}&per_page=${perPage}`
  axios.get(url).then(response => {
      store.dispatch(setInterviewsData(response.data));
      // console.log(response.data);
  })
  .catch(error => {
      if (error.response) console.log('Error:', error.response);
  });
}

export const fetchPastInterviews = (page=1, perPage=20) =>  {
  let url = SERVICE_URL + `/webinars/past_interviews?page=${page}&per_page=${perPage}`
  axios.get(url).then(response => {
      store.dispatch(setPastInterviewsData(response.data));
  })
  .catch(error => {
      if (error.response) console.log('Error:', error.response);
  });
}
